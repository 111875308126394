import React from 'react';
import './faq.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/cta';

export default () => (
  <Layout>
    <SEO title="Frequently Asked Questions" />
    <div className="constrain">
      <div className="faq">
        <h3 className="faq__heading">Frequently Asked Questions</h3>
        <p className="faq__hero">PLACEHOLDER_FAQ_TEXT</p>
        <div className="faq__paragraphs">
          <p>
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus
            mollis interdum. Etiam porta sem malesuada magna mollis euismod.
            Maecenas faucibus mollis interdum. Donec ullamcorper nulla non metus
            auctor fringilla. Praesent commodo cursus magna, vel scelerisque
            nisl consectetur et.
          </p>
          <p>
            Sed posuere consectetur est at lobortis. Praesent commodo cursus
            magna, vel scelerisque nisl consectetur et. Cras justo odio, dapibus
            ac facilisis in, egestas eget quam. Donec sed odio dui. Etiam porta
            sem malesuada magna mollis euismod.
          </p>
        </div>
      </div>
      <CTA />
    </div>
  </Layout>
);
